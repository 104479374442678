import instance from "api/axiosInstance";
import { Company } from "api/company.api";
import { FileUploadButton } from "components/buttons/FileUploadButton";
import { DescriptionList } from "components/desriptionList/DescriptionList";
import { Item } from "components/desriptionList/Item";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import TabContainer from "components/tab/TabContainer";
import { guid } from "helpers/guid";
import { useNotification } from "hooks/useNotification";
import { FC, useEffect } from "react";
import { useMutation } from "react-query";

interface Props {
  company: Company;
  setLogoUrl: (url: string) => void;
  setCertificateLogoUrl: (certificateLogoUrl: string) => void;
}

export const OverviewTab: FC<Props> = ({
  company,
  setLogoUrl,
  setCertificateLogoUrl,
}) => {
  return (
    <TabContainer>
      <DescriptionList>
        <Item>
          <Label>Namn på ert företag</Label>
          <Value>{company.name}</Value>
        </Item>
        <Item>
          <Label>Organisationsnummer</Label>
          <Value>{company.organizationNumber}</Value>
        </Item>
        <Item>
          <Label>Telefon</Label>
          <Value>{company.phone}</Value>
        </Item>
        <Item>
          <Label>Adress</Label>
          <Value>
            <div className="space-y-1">
              {!!company.address && <p>{company.address}</p>}
              {!!company.postalCode && <p>{company.postalCode}</p>}
              {!!company.city && <p>{company.city}</p>}
            </div>
          </Value>
        </Item>
        <FileZone label="Logga" url={company.logoUrl} setUrl={setLogoUrl} />
        <FileZone
          label="Certifiering/ackreditering (för kontrollrapporter)"
          url={company.certificateLogoUrl}
          setUrl={setCertificateLogoUrl}
        />

        <Item>
          <Label>Kontaktuppgifter för rapportering till Avfallsregistret</Label>
          <Value>
            <div className="space-y-1">
              {!!company.contactFullName && <p>{company.contactFullName}</p>}
              {!!company.contactEmail && <p>{company.contactEmail}</p>}
              {!!company.contactPhone && <p>{company.contactPhone}</p>}
            </div>
          </Value>
        </Item>
      </DescriptionList>
    </TabContainer>
  );
};

type FileZoneProps = {
  label: string;
  url?: string;
  setUrl: (url: string) => void;
};
const FileZone = ({ label, url, setUrl }: FileZoneProps) => {
  const notification = useNotification();

  const mutation = useMutation(
    async (payload: File[]): Promise<string[]> => {
      const formData = new FormData();
      // console.log({ payload });
      payload.forEach((file) => formData.append("files", file));
      // console.log({ formData });
      return (
        await instance.post("/image/upload-many", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;
    },
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );

  const onLogoAdd = async (blob: Blob | null, filename: string) => {
    if (!blob) return;
    const file = new File([blob], filename);
    if (!file) {
      return;
    }

    const renamedFile = new File(
      [file],
      `${filename.split(".")[0]}-${guid().split("-")[0]}${
        guid().split("-")[1]
      }.${file.name.split(".")[1]}`
    );

    mutation.mutate([renamedFile]);
  };

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      setUrl(mutation.data[0]);
      mutation.reset();
    }
  }, [mutation, setUrl]);

  const uploadedUrl =
    !!mutation.data && mutation.data?.length > 0 ? mutation.data[0] : "";

  return (
    <Item>
      <Label>{label}</Label>
      <Value>
        {(!!url || !!uploadedUrl) && (
          <img className="h-12 mb-4" src={url ?? uploadedUrl} alt="Your logo" />
        )}
        <FileUploadButton
          id={label}
          onFileAdd={onLogoAdd}
          isLoading={mutation.isLoading}
          accept="image/*,image/heic"
          label={!!url ? "Byt" : "Ladda upp"}
        />
      </Value>
    </Item>
  );
};
