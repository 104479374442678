import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import instance, { getAxiosErrorMessage } from "./axiosInstance";

export const ENDPOINT = "/company";
export enum CompanyQueryKey {
  GetOne = "companygetone",
  GetAll = "companygetall",
}

export const useCompanyList = () =>
  useQuery(
    [CompanyQueryKey.GetAll],
    async (): Promise<Company[]> => (await instance.get(ENDPOINT)).data
  );

export const useCompany = (id: number) =>
  useQuery(
    [CompanyQueryKey.GetOne, id],
    async (): Promise<Company> => (await instance.get(`${ENDPOINT}/${id}`)).data
  );

export const useAddCompany = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  return useMutation(
    async (payload: AddCompanyDTO) =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CompanyQueryKey.GetAll);
        notification.info("Företaget skapat");
      },
      onError: (err) => {
        // console.log(getAxiosErrorMessage(err));
        notification.error(getAxiosErrorMessage(err));
      },
    }
  );
};

export const useUpdateCompany = (id: number) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: UpdateCompanyDTO): Promise<Company> =>
      (await instance.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: () => {
        notification.info(`Ändringarna sparade`);
        queryClient.invalidateQueries([CompanyQueryKey.GetOne, id]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export type Company = {
  id: number;
  uuid: number;
  name: string;
  organizationNumber: string;
  contactFullName: string;
  contactPhone: string;
  contactEmail: string;
  contactName: string;
  logoUrl?: string;
  certificateLogoUrl?: string;
  phone?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  isNvActive: boolean;
};

export type AddCompanyDTO = {
  companyName: string;
  organizationNumber: string;
  personFullName: string;
  email: string;
};

export type UpdateCompanyDTO = {
  name: string;
  organizationNumber: string;
  logoUrl?: string;
  certificateLogoUrl?: string;
  phone?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  contactFullName: string;
  contactPhone: string;
  contactEmail: string;
};
