import { Transition } from "components/animations/Transition";
import heic2any from "heic2any";
import { isExcel, isPdf } from "helpers/file";
import { FC, useState } from "react";
import { config } from "react-spring";

interface Props {
  isLoading?: boolean;
  onFileAdd: (blob: Blob | null, filename: string) => Promise<void>;
  accept?: string;
  label?: string;
  id?: string;
}

// Denna ska kunna ladda upp en fil direkt, eftersom jag som jag byggt det alltid
// laddar upp filen när jag
export const FileUploadButton: FC<Props> = ({
  isLoading,
  onFileAdd,
  accept = "image/*,image/heic,application/pdf,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
  label = "Ladda upp fil",
  id = "file-upload",
}) => {
  const [isCompressingHeic, setIsCompressingHeic] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const maxSize = 1000 * 1000;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      let file = event.target.files?.[0];
      if (!file) return;
      if (isPdf(file.name) || isExcel(file.name)) {
        onFileAdd(file, file.name);
        return;
      }
      setIsWorking(true);
      if (file.name.includes(".HEIC")) {
        setIsCompressingHeic(true);
        const blob = await convertHEIC(file);
        if (Array.isArray(blob) || !blob) return;
        file = new File([blob], file.name);
      }
      const filenameWithoutType = file.name?.split(".")[0] ?? "";
      // Scale the image to be at most maxSize bytes in size
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          const maxDimension = Math.max(image.width, image.height);
          const scaleFactor = maxDimension > 1000 ? 1000 / maxDimension : 1;
          const canvas = document.createElement("canvas");
          canvas.width = image.width * scaleFactor;
          canvas.height = image.height * scaleFactor;
          canvas
            .getContext("2d")
            ?.drawImage(image, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (scaledImage) =>
              onFileAdd(scaledImage, `${filenameWithoutType}.jpeg`),
            "image/jpeg",
            maxSize / canvas.width / canvas.height
          );
          setIsCompressingHeic(false);
          setIsWorking(false);
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setIsWorking(false);
    }
  };

  const convertHEIC = async (file: File) => {
    // get image as blob url
    let blobURL = URL.createObjectURL(file);

    // convert "fetch" the new blob url
    let blobRes = await fetch(blobURL);

    // convert response to blob
    let blob = await blobRes.blob();

    // convert to PNG - response is blob
    let conversionResult = await heic2any({ blob });

    return Array.isArray(conversionResult)
      ? conversionResult[0]
      : conversionResult;
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 cursor-pointer"
      >
        <span className="flex">
          {(isLoading || isWorking) && (
            <svg
              className="w-4 h-4 mr-3 -ml-1 text-gray-400 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isLoading || isWorking ? <>Laddar upp...</> : <>{label}</>}
        </span>
        <input
          id={id}
          name={id}
          type="file"
          className="sr-only"
          onChange={handleFileChange}
          accept={accept}
        />
      </label>
      <Transition
        settings={{
          from: {
            opacity: 0,
            transform: "translateY(-20px)",
            position: "absolute",
          },
          enter: {
            opacity: 1,
            transform: "translateY(0px)",
            position: "absolute",
          },
          config: config.slow,
          precision: 0.03,
        }}
        isVisible={isCompressingHeic}
      >
        <p className="py-1 text-cyan-600 px-1 flex items-center text-sm">
          Bilden komprimeras, det kan ta upp till 10 sekunder
          <svg
            aria-hidden="true"
            className="ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-cyan-400 w-4 h-4"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </p>
      </Transition>
    </div>
  );
};
